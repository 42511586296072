.cookies-msg {
  display: block;
  position: fixed;
  background-color: #fbfbfb;
  border-radius: var(--border-radius);
  width: 65%;
  bottom: 2%;
  left: 5%;
}

.cookies-msg .cookies-txt {
  display: flex;
  align-items: center;
  text-align: justify;
  padding: 2%;
}

.cookies-msg .cookies-btn {
  margin-left: 9%;
}

.cookies-msg .cookies-btn button {
  border: none;
  background-color: var(--laranja);
  color: var(--preto);
  font-weight: 700;
  font-family: "Jost", sans-serif;
  font-size: 1.2rem;
  padding: .5rem 1rem;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.2s;
  border-radius: var(--border-radius);
}

.cookies-btn button:hover {
  background-color: var(--laranja-escuro);
}

@media only screen and (max-width: 1300px) {
  .cookies-msg {
    width: 58%;
    height: 20%;
  }

  .cookies-msg .cookies-txt p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1050px) {
  .cookies-msg {
    width: 70%;
    font-size: 15px;
  }

  .cookies-msg .cookies-btn button {
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 768px) {
  .cookies-msg {
    height: 130px;
  }

  .cookies-msg .cookies-btn {
    margin-left: 4%;
  }

  .cookies-msg .cookies-btn button {
    display: flex-end;
    flex-direction: column;
    padding: 5px 1px;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 571px) {
  .cookies-msg .cookies-txt p {
    font-size: 13px;
  }

  .cookies-msg .cookies-btn button {
    font-size: 13px;
  }
}

@media only screen and (max-width: 484px) {
  .cookies-msg .cookies-txt {
    flex-direction: column;
    align-items: flex-start;
  }

  .cookies-msg .cookies-btn button {
    padding: 3px 1px;
  }

  .cookies-msg .cookies-txt p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 380px) {
  .cookies-msg {
    height: auto;
  }
}