.product-section {
  padding: 6rem 3rem;
  background-color: var(--branco);
}

.product-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
}

.product-section-content h1 {
  font-weight: bold;
  font-size: 3rem;
  width: 40%;
  text-align: center;
}

.products-categories {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.react-3d-carousel {
  display: none;
}

@media only screen and (max-width: 1160px) {
  .product-section-content h1 {
    width: 50%;
  }
}

@media only screen and (max-width: 960px) {
  .product-section-content h1 {
    width: 68%;
  }
}

@media only screen and (max-width: 850px) {
  .product-section {
    padding: 5rem 2rem;
  }
}

@media only screen and (max-width: 730px) {
  .product-section-content h1 {
    width: 80%;
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 610px) {
  .product-section {
    padding: 4.5rem 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .product-section-content h1 {
    font-size: 2.3rem;
  }
}

@media only screen and (max-width: 600px) {
  .product-section-content h1 {
    width: 90%;
  }
}

@media only screen and (max-width: 460px) {
  .product-section-content h1 {
    font-size: 2rem;
    width: 100%;
    text-align: start;
  }
}

@media only screen and (max-width: 400px) {
  .products-categories {
    display: none;
  }

  .react-3d-carousel {
    display: block;
    width: 100%;
  }

  .product-section-content {
    height: 400px;
  }
}

@media only screen and (max-width: 360px) {
  .product-section {
    padding: 2rem 1rem;
  }
}

@media only screen and (max-width: 320px) {
  .product-section-content h1 {
    font-size: 1.5rem;
  }
}