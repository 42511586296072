.background {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn .5s;
}

.modal-wrapper {
  width: 900px;
  height: 500px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background-color: #000000;
  color: #fafafa;
  display: flex;
  position: relative;
  z-index: 10;
  border-radius: 8px;
  box-sizing: border-box;
  animation: fadeIn .5s;
}

.modal-form {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-img {
  width: auto;
  height: 500px;
  border-radius: 8px 0px 0px 8px;
  background: #000;
  position: absolute;
}

.contact-info {
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  box-sizing: border-box;
}

.contact-infos {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-top: 3rem;
}

.info-plus-icon {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.info-plus-icon img {
  width: auto;
  height: 1.2rem;
  max-width: 1rem;
}

.info-plus-icon a {
  text-decoration: none;
  font-weight: 400;
  color: var(--branco);
  font-size: 1.1rem;
}

.contact-header h1 {
  font-weight: 700;
  font-size: 2rem;
}

.contact-header p {
  margin-top: 1rem;
  line-height: 1.5;
}

.modal-social-media {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.modal-social-media-logos {
  display: flex;
  column-gap: 1.5rem;
}

.modal-social-media-logos a img {
  width: auto;
  height: 1.2rem;
  color: var(--branco);
}

.modal-contact-form {
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
}

.modal-contact-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4px;
  width: 100%;
  color: var(--branco);
}

#email {
  width: 315px;
}

#phone {
  width: 140px;
}

.modal-text {
  border: 1px solid var(--branco);
  box-sizing: border-box;
  border-radius: var(--border-radius);
  height: 2.2rem;
  background-color: var(--preto);
  width: 100%;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: var(--branco);
  padding: 10px;
}

.modal-row {
  display: flex;
  column-gap: 2rem;
}

.what-do-u-need {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.modal-contact-check {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modal-check {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.modal-check input {
  all: unset;
  border: 1px solid var(--branco);
  width: 16px;
  height: 16px;
  display: inline-block;
  cursor: pointer;
  border-radius: var(--border-radius);
}

.modal-check input:checked {
  background-color: var(--laranja);
}

.modal-text-proposta {
  border: 1px solid var(--branco);
  box-sizing: border-box;
  border-radius: var(--border-radius);
  height: 10rem;
  min-height: 10rem;
  max-height: 10rem;
  background-color: var(--preto);
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: var(--branco);
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 10px;
}

.modal-contact-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 3rem;
}

.is-invalid {
  border: 1px solid red;
}

.invalid-feedback {
  font-weight: 400;
  font-size: 0.9rem;
  color: red;
}

.orange-button-progress {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background: #0000003a;
}

.button-text {
  position: relative;
}

.orange-button-width {
  width: 180px;
}

.modal-success {
  width: 900px;
  height: 500px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  background: url("../../img/backgrounds/fundo-abstrato-centro.svg");
  justify-content: center;
}

.success-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  row-gap: 1rem;
}

.success-text h1 {
  font-weight: 700;
  font-size: 3.5rem;
}

.success-text h2 {
  font-weight: 700;
  font-size: 1.5rem;
}

.success-text h3 {
  font-weight: 400;
  font-size: 1rem;
}