.footer {
  padding: 3rem 4.5rem 1rem 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--cinza-secundario);
  font-weight: 400;
  font-size: 14px;
}

.footer a {
  text-decoration: none;
  color: var(--cinza-secundario);
}

.footer-content {
  display: flex;
  column-gap: 4.5rem;
  row-gap: 2.3rem;
  flex-wrap: wrap;
}

.company-data {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.logo-btk-footer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.logo-btk-footer a {
  width: 100%;
}

.logo-btk-footer a img {
  height: 3rem;
}

.company-info {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.company-info h2 {
  font-weight: 600;
  font-size: 18px;
}

.social-media-logos {
  display: flex;
  column-gap: 1.5rem;
}

.social-media-logos a img {
  width: auto;
  height: 1.5rem;
}

.social-media {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.footer-links {
  display: flex;
  column-gap: 4.5rem;
}

.products-links {
  display: flex;
  flex-direction: column;
}

.products-links h2 {
  font-weight: 600;
  font-size: 18px;
}

.products-links li {
  margin-top: 1rem;
}

.company-links {
  display: flex;
  flex-direction: column;
}

.company-links h2 {
  font-weight: 600;
  font-size: 18px;
}

.company-links li {
  margin-top: 1rem;
}

@media only screen and (max-width: 850px) {
  .footer {
    padding: 3rem 2rem 1rem 2rem;
  }
}

@media only screen and (max-width: 800px) {
  .footer {
    padding: 3rem 2rem 1rem 2rem;
  }
}