.about-us-section {
  padding: 4.5rem 15rem;

  display: flex;
  flex-direction: column;

  background: var(--branco);

  row-gap: 4.5rem;
}

.how-we-work {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}

.how-we-work-title {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.how-we-work-title h2 {
  font-weight: bold;
  font-size: 3rem;
  width: 50%;
}

.how-we-work-title p {
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--preto-secundario);
  width: 70%;
}

.how-we-work-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.points {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clients {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client {
  height: 2.5rem;
}

.how-we-work-saiba-mais-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.how-we-work-saiba-mais {
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  cursor: pointer;
}
.how-we-work-saiba-mais a {
  text-decoration: none;
  color: var(--preto);
}

.how-we-work-saiba-mais:hover img {
  transform: translateX(5px);
}

@media only screen and (max-width: 1300px) {
  .about-us-section {
    padding: 4.5rem 10rem;
  }
}

@media only screen and (max-width: 1110px) {
  .about-us-section {
    padding: 4.5rem 7rem;
  }

  .how-we-work-title h2 {
    width: 60%;
  }
}

@media only screen and (max-width: 950px) {
  .about-us-section {
    padding: 4.5rem 5rem;
  }
}

@media only screen and (max-width: 850px) {
  .about-us-section {
    padding: 4.5rem 2rem;
  }
}

@media only screen and (max-width: 800px) {
  .how-we-work {
    align-items: center;
  }

  .how-we-work-title p {
    width: 90%;
  }

  .clients {
    flex-wrap: wrap;
    width: 80%;
    justify-content: space-evenly;
    row-gap: 1rem;
  }
}

@media only screen and (max-width: 720px) {
  .how-we-work h2 {
    width: 80%;
  }
}

@media only screen and (max-width: 610px) {
  .about-us-section {
    padding: 4.5rem 1rem;
  }
}

@media only screen and (max-width: 525px) {
  .how-we-work h2 {
    width: 100%;
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 450px) {
  .client {
    height: 2rem;
  }
}

@media only screen and (max-width: 360px) {
  .points {
    flex-direction: column;
    padding: 0rem 1rem;
    align-items: center;
    box-sizing: border-box;
    row-gap: 2rem;
  }

  .how-we-work h2 {
    font-size: 2rem;
  }

  .how-we-work p {
    font-size: 1rem;
  }
}
