:root {
  /*Cor*/
  --preto: #000;
  --branco: #fafafa;
  --laranja: #f08719;
  --laranja-escuro: #ff9728;
  --azul: #1f69b1;
  --cinza-secundario: #c4c4c4;
  --preto-secundario: #4f4f4f;

  /*Configs*/
  --border-radius: 2px;
}

h1 {
  font-family: 'Geologica', sans-serif;
  font-weight: 700;
}

h2 {
  font-family: 'Geologica', sans-serif;
  font-weight: 700;
}

p {
  font-family: 'Jost', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

body {
  height: 100%;
  width: 100%;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none !important;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.App {
  display: flex;
  flex-direction: column;

  background: #000;

  font-family: 'Jost', sans-serif;
}

.AppBody {
  padding-top: 4.5rem;
  display: flex;
  flex-direction: column;
}

.orange-button {
  height: 100%;
  padding: 0rem 1.5rem;
  border-radius: var(--border-radius);
  position: relative;

  background-color: var(--laranja);
  color: var(--preto);
  font-weight: bold;
  font-family: 'Jost', sans-serif;
  font-size: 18px;

  border: none;
  cursor: pointer;
}

.orange-button:hover {
  background-color: var(--laranja-escuro);
}

.black-button {
  height: 100%;
  padding: 0rem 1rem;
  border-radius: var(--border-radius);
  position: relative;

  background-color: var(--preto);
  color: var(--branco);
  font-weight: bold;
  font-family: 'Jost', sans-serif;
  font-size: 18px;

  border: 1px solid var(--branco);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.black-button div {
  margin-top: 11px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.black-button:hover img {
  transform: translateX(5px);
}