.solve-your-problem-section {
  display: flex;
  align-items: center;
  padding: 6rem 8rem;
}

.solve-action {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 3rem;
}

.solve-action h1 {
  font-weight: bold;
  font-size: 4rem;
  color: var(--branco);
  width: 100%;
}

.buttons {
  display: flex;
  column-gap: 0.625rem;
  height: 3rem;
  flex-wrap: wrap;
  row-gap: 0.625rem;
}

.solve-img {
  width: 50%;
  max-height: 300px;
}

.solve-img img {
  width: auto;
  height: 100%;
}

@media only screen and (max-width: 1110px) {
  .solve-your-problem-section {
    padding: 4.5rem 7rem;
  }
}

@media only screen and (max-width: 950px) {
  .solve-your-problem-section {
    padding: 4.5rem 5rem;
  }

  .solve-action h1 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 900px) {
  .solve-img {
    display: none;
  }

  .solve-action {
    width: 95%;
  }
}

@media only screen and (max-width: 850px) {
  .solve-your-problem-section {
    padding: 4.5rem 3rem;
  }
}

@media only screen and (max-width: 700px) {
  .solve-your-problem-section {
    padding: 4.5rem 2rem;
  }

  .solve-action {
    width: 100%;
  }

  .buttons {
    height: auto;
    width: 60%;
  }

  .buttons .orange-button,
  .buttons .black-button {
    height: 3rem;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .solve-action h1 {
    font-size: 3.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .buttons {
    width: 70%;
  }
}

@media only screen and (max-width: 540px) {
  .solve-action h1 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 420px) {
  .solve-action h1 {
    font-size: 2.5rem;
  }
  .buttons {
    width: 85%;
  }
}

@media only screen and (max-width: 360px) {
  .solve-action h1 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 350px) {
  .solve-action h1 {
    font-size: 1.8rem;
  }

  .buttons .orange-button,
  .buttons .black-button {
    font-size: 12px;
    height: 2rem;
  }

  .black-button div {
    margin-top: 3px;
  }
}
