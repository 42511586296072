.hero-action {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 50rem;

  row-gap: 2rem;

  background-color: var(--preto);
}

.hero-message {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  row-gap: 1rem;
}

.hero-title {
  font-weight: bold;
  font-size: 5rem;
  color: var(--branco);
  font-family: 'Geologica', sans-serif;
  width: 60%;
}

.hero-text {
  width: 78%;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--cinza-secundario);
}

.features {
  display: flex;
  justify-content: space-between;
}

.hero-frase {
  font-weight: bold;
  font-size: 3rem;
  color: var(--branco);
  font-family: 'Geologica', sans-serif;
  overflow: hidden;
  border-right: .15em solid var(--branco);
  align-items: center;
  line-height: 4rem;
  height: 4rem;

  animation: typing 3s steps(40, end), blink-caret .75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent
  }

  50% {
    border-color: var(--branco)
  }
}

@media only screen and (max-width: 1920px) {
  .hero-title {
    font-weight: bold;
    font-size: 4rem;
    width: 60%;
  }

  .hero-text {
    width: 80%;
    font-size: 1.3rem;
  }

  .hero-frase {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 1300px) {
  .features {
    column-gap: 1.5rem;
  }
}

@media only screen and (max-width: 950px) {
  .hero-text {
    width: 84%;
  }

  .hero-frase {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 850px) {
  .hero-text {
    width: 90%;
    line-height: 140%;
  }

  .hero-frase {
    font-size: 1.8rem;
  }

  .features {
    flex-direction: column;
    row-gap: 3rem;
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .hero-text {
    width: 100%;
  }

  .hero-frase {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 610px) {
  .hero-action {
    width: 100%;
  }

  .hero-title {
    font-size: 4rem;
  }

  .hero-text {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 550px) {
  .hero-title {
    font-size: 3.5rem;
  }

  .hero-text {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 420px) {
  .hero-action {
    row-gap: 1rem;
  }

  .hero-title {
    font-size: 2rem;
    width: 90%;
  }

  .hero-text {
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 340px) {
  .features {
    column-gap: 0.5rem;
  }
}

@media only screen and (max-width: 310px) {
  .hero-title {
    font-size: 1.9rem;
  }

  .hero-text {
    font-size: 0.8rem;
    width: 90%;
  }
}