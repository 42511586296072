.about-us {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;

  background-color: var(--branco);
  padding: 3rem 6rem;
  padding-bottom: 7rem;
}

.about-us-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.about-us-left-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
}

.about-us-left-column span {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.about-us h1 {
  font-size: 4rem;
  line-height: 4.5rem;
}

.low-rectangle {
  width: 2rem;
  height: 0.40rem;
  background: #D9D9D9;
}

.med-rectangle {
  width: 18rem;
  height: 0.75rem;
  background: #D9D9D9;
}

.big-rectangle {
  width: 36rem;
  height: 12rem;
  background: #D9D9D9;
  position: absolute;
}

.about-us-right-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.nos {
  margin-left: -2rem;
}

.about-us h2 {
  font-size: 2.5rem;
  line-height: 3rem;
}

.about-us p {
  font-size: 1.125rem;
}

.about-us section {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.history-footer {
  height: 30rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.history-imgs {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 2.5rem;
  padding-left: 40rem;
}

.hand-shake {
  margin-left: -14rem;
  margin-top: -8rem;
  width: auto;
  height: 13rem;
}

.our-mission {
  padding-right: 8rem;
}

.about-us-right-column {
  align-items: end;
}

.about-us-right-column .med-rectangle {
  width: 38rem;
}

.mission-footer {
  height: 30rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mission-imgs {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 2.5rem;
  padding-left: 40rem;
}

.fist-bump {
  margin-top: -10rem;
  width: auto;
  height: 16rem;
}

.gdr-mission {
  margin-left: -14rem;
  margin-top: -8rem;
  width: auto;
  height: 14rem;
}

.mission-footer .big-rectangle {
  margin-left: 34rem;
}

.expertise-left-column {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: end;
}

.expertise-left-column .med-rectangle {
  width: 24rem;
}

.expertise-content {
  display: flex;
  flex-direction: row;
  column-gap: 5rem;
  align-items: center;
}

.expertise-text {
  display: flex;
  flex-direction: column;
  width: 40rem;
}

.expertise-column {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.expertise-creator {
  width: auto;
  height: 14rem;
}

.expertise-product {
  width: auto;
  height: 14rem;
}

.our-client-left-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -3rem;
}

.our-client-left-column .med-rectangle {
  width: 3rem;
  rotate: 90deg;
}

.our-client-content {
  display: flex;
  flex-direction: row;
  column-gap: 5rem;
  align-items: center;
}

.our-client-text {
  display: flex;
  flex-direction: column;
  width: 40rem;
}

.our-client-column {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.our-client-five {
  position: absolute;
  width: auto;
  height: 18rem;
  margin-top: -8rem;
}

.our-client-column .big-rectangle {
  rotate: 90deg;
  width: 22rem;
  height: 7rem;
  margin-left: 9rem;
  margin-top: -6rem;
}

.team {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-title {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 2rem;
}

.team-imgs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
}

.team-img {
  width: auto;
  height: 15rem;
}

.btk-wrench {
  width: auto;
  height: 5rem;
}

.team-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-subtitle h3 {
  font-size: 1.5rem;
  font-weight: 400;
}

.team-text {
  width: 50rem;
}

.inovation {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inovation-right-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
  margin-right: -15rem;
}

.inovation-right-column .med-rectangle {
  width: 38rem;
}

.inovation-text {
  width: 50rem;
}