.privacy {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;

  background-color: var(--branco);
  padding: 3rem 6rem;
  padding-bottom: 7rem;

  font-size: 1.3rem;
  line-height: 1.8rem;
}

.privacy-introduction {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

}

.privacy-introduction h1 {
  font-size: 3rem;
  font-weight: bold;
}

.privacy-topics {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.privacy-topics li {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.privacy-topics h2 {
  font-weight: bold;
  font-size: 1.7rem;
}

.privacy-topics h3 {
  font-weight: bold;
}

.topics-list-dots {
  list-style: square;
  list-style-position: inside;
}

.topics-list-dots li {
  display: list-item;
}