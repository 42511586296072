.feature {
  display: flex;
  align-items: center;
  column-gap: 3px;
  width: 30%;
}

.data {
  font-weight: bold;
  font-size: 3.8rem;
  color: var(--branco);
}

.description {
  font-weight: 300;
  font-size: 1.5rem;
  color: var(--cinza-secundario);
}

@media only screen and (max-width: 1980px) {
  .data {
    font-size: 2.3rem;
  }

  .description {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 880px) {
  .feature {
    width: 100%;
    flex-direction: column;
  }
}