.products {
  display: flex;
  flex-direction: column;

  background-color: var(--branco);
  padding: 3rem 6rem;
  padding-bottom: 7rem;
}

.products h1 {
  font-weight: bold;
  font-size: 4rem;
}

.products-list {
  display: flex;
  flex-direction: column;
  row-gap: 5rem;
  padding-top: 5rem;
}

@media only screen and (max-width: 750px) {
.products h1 {
  font-size: 2.5rem;
}
}

@media only screen and (max-width: 500px) {
.products {
  padding: 2rem 1rem;
}

.products h1 {
  font-size: 2rem;
}
}