.contact-section {
  padding: 4.5rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
  width: 40%;
}

.form-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
}

.form-title h2 {
  font-weight: bold;
  font-size: 2rem;
  color: var(--branco);
}

.form-title span {
  font-weight: 400;
  font-size: 18px;
  color: var(--cinza-secundario);
}

.invalid-feedback {
  font-weight: 400;
  font-size: 0.9rem;
  color: red;
}

.contact-email {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4px;
  width: 100%;
}

.label {
  font-weight: 600;
  font-size: 18px;
  color: var(--branco);
}

.contact-proposta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4px;
  width: 100%;
}

.text-email {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  height: 2.2rem;
  background-color: var(--preto);
  width: 100%;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: var(--cinza-secundario);
  padding: 10px;
}

.text-proposta {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  height: 8rem;
  min-height: 8rem;
  max-height: 8rem;
  background-color: var(--preto);
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: var(--cinza-secundario);
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 10px;
}

.contact-buttons {
  height: 3rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--cinza-secundario);
  -webkit-box-shadow: 0 0 0px 0px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.is-invalid {
  border: 1px solid red;
}

@media only screen and (max-width: 1000px) {
  .contact-form {
    width: 60%;
  }
}

@media only screen and (max-width: 700px) {
  .contact-form {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .contact-section {
    padding: 4.5rem 1rem;
  }

  .contact-form {
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .contact-form {
    width: 100%;
  }

  .label,
  .form-title span,
  .text-email,
  .text-proposta {
    font-size: 12px;
  }
}