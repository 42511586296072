.landing-page-3900 {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;

  background-color: var(--preto);
  padding: 2rem 6rem;
  padding-bottom: 7rem;

  font-size: 1rem;
  line-height: 1.8rem;
  color: var(--branco);

  row-gap: 8rem;
}

.sku {
  font-size: 10em;
  color: var(--laranja);
  font-family: 'teko';
}

h1 {
  font-size: 8rem;
  font-weight: 700;
  line-height: 8.5rem;
}

.product-data-lp {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}

.product-data-lp h2 {
  font-family: 'Jost';
  font-size: 4rem;
  color: var(--laranja-escuro);
  font-weight: 600;
}


h3 {
  font-size: 3rem;
  font-weight: 500;
}

.lp-hero {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 65vh;

}

.lp-hero-title {
  width: 60%;
  display: flex;
  flex-direction: column;
  row-gap: 6rem;
}

.lp-hero-call {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}

.lp-hero-image {
  padding-right: 8rem;
  height: 100%;
}

.img-3900 {
  height: 100%;
  rotate: 10deg;
}

.action {
  display: flex;
  flex-direction: column;
  row-gap: 5rem;
  width: 100%;
}

.lp-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 10rem;
}

.lp-content-video {
  position: relative;
}

.lp-content-video video {
  object-fit: cover;
  position: relative;
}

.overlay-video {
  position: absolute;
  background-color: none;
  width: 100%;
  height: 100%;
  top: 0;
  transition: .5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay-video:hover {
  background-color: rgba(0, 0, 0, 0.568);
}

.material-symbols-outlined {
  transition: .3s ease-in-out;
  user-select: none;
  font-size: 10rem;
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

.unmuted {
  align-items: end;
  justify-content: end;
  font-size: 3rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
}

.product-specs {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  font-size: 2.5rem;
}

.product-specs li {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
}

.product-specs li span {
  font-size: 2.5rem;
}

.product-module h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--laranja-escuro);
}

.lp-buttons-row {
  display: flex;
  height: 4rem;

  column-gap: 2rem;
}

.lp-buttons-row button {
  width: 20rem;
  font-size: 1.5rem;
}

.lp-buttons-row .orange-button {
  color: var(--preto);
}

.black-button a {
  appearance: none;
  color: var(--branco);
}

@media only screen and (max-width: 1920px) {
  .product-specs {
    font-size: 2.2rem;
  }
}

@media only screen and (max-width: 1710px) {
  .landing-page-3900 {
    row-gap: 5rem;
  }

  .sku {
    font-size: 7em;
    color: var(--laranja);
    font-family: 'teko';
  }

  h1 {
    font-size: 6rem;
    font-weight: 700;
    line-height: 6.5rem;
  }

  .product-data-lp {
    row-gap: 2rem;
  }

  .product-data-lp h2 {
    font-size: 2.5rem;
  }


  h3 {
    font-size: 2rem;
  }

  .action {
    row-gap: 3rem;
  }

  .lp-content {
    column-gap: 3rem;
  }

  .product-specs {
    row-gap: 1.5rem;
    font-size: 2rem;
  }

  .product-module h2 {
    font-size: 1.5rem;
  }

  .lp-content-video {
    width: 50%;
  }

  .lp-content-video video {
    height: auto;
    width: 100%;
  }
}

@media only screen and (max-width: 1550px) {
  .landing-page-3900 {
    padding: 4rem 6rem;
    padding-bottom: 7rem;

    row-gap: 8rem;
  }

  .lp-hero-title {
    row-gap: 4rem;
  }

  .lp-hero-call {
    row-gap: 1rem;
  }

  .sku {
    font-size: 6rem;
  }

  h1 {
    font-size: 5rem;
    font-weight: 700;
    line-height: 5.5rem;
  }

  .product-data-lp {
    row-gap: 3rem;
  }

  .product-data-lp h2 {
    font-size: 2rem;
  }


  h3 {
    font-size: 1.8rem;
  }

  .action {
    row-gap: 3rem;
  }

  .lp-content {
    column-gap: 3rem;
  }

  .product-specs {
    row-gap: 1.5rem;
    font-size: 1.8rem;
  }

  .product-module h2 {
    font-size: 1.5rem;
  }

  .lp-content-video {
    width: 40%;
  }

  .lp-content-video video {
    height: auto;
    width: 100%;
  }
}

@media only screen and (max-width: 1300px) {
  .landing-page-3900 {
    padding: 0rem 3rem;

    row-gap: 4rem;
  }

  .lp-hero-title {
    row-gap: 2rem;
  }

  .lp-hero-call {
    row-gap: 1rem;
  }

  .sku {
    font-size: 4em;
  }

  .lp-hero-image {
    width: auto;
    height: 70%;
    padding-right: 0;
  }

  h1 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3.5rem;
  }

  .product-data-lp {
    row-gap: 1.5rem;
  }

  .product-data-lp h2 {
    font-size: 1.5rem;
  }


  h3 {
    font-size: 1.3rem;
  }

  .action {
    row-gap: 3rem;
  }

  .lp-content {
    column-gap: 3rem;
  }

  .product-specs {
    row-gap: .6rem;
    font-size: 1.1rem;
  }

  .product-module h2 {
    font-size: 1.5rem;
  }

  .lp-content-video {
    width: 60%;
  }

  .lp-content-video video {
    height: auto;
    width: 100%;
  }

  .lp-buttons-row button {
    width: 13rem;
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 750px) {
  .landing-page-3900 {
    padding: 0rem 3rem;

    row-gap: .1rem;
  }

  .lp-hero {
    position: relative;
    flex-direction: column;
    height: 50vh;
  }

  .lp-hero-title {
    row-gap: 5rem;
    width: 100%;
  }

  .lp-hero-call {
    line-height: .1rem;
    display: flex;
    flex-direction: column;
    row-gap: .1rem;
  }

  .sku {
    font-size: 2rem;
    line-height: 2rem;
  }

  .lp-hero-image {
    position: absolute;
    width: auto;
    height: 60%;
    top: 80px;
    right: 0;
  }

  h1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
  }

  .product-data-lp {
    row-gap: .8rem;
  }

  .product-data-lp h2 {
    font-size: 1rem;
  }


  h3 {
    line-height: 1rem;
    width: 45%;
    font-size: .7rem;
  }

  .action {
    row-gap: 2rem;
  }

  .lp-content {
    flex-direction: column;
    row-gap: 1rem;
  }

  .product-specs {
    row-gap: .3rem;
    font-size: .7rem;
  }

  .product-module h2 {
    font-size: 1rem;
  }

  .lp-content-video {
    width: 100%;
  }

  .lp-content-video h2 {
    font-size: .5rem;
  }

  .lp-content-video video {
    height: auto;
    width: 100%;
  }

  .lp-buttons-row {
    flex-direction: column;
    row-gap: 1rem;
    padding-bottom: 7rem;
  }

  .lp-buttons-row button {
    width: 12rem;
    height: 2.8rem;
    font-size: 1rem;
  }

  .product-specs li span {
    font-size: 1rem;
  }

  .material-symbols-outlined {
    font-size: 2rem;
  }

  .overlay-video:hover {
    background-color: rgba(0, 0, 0, 0);
  }
}

@media only screen and (max-width: 400px) {
  .landing-page-3900 {
    padding: 0rem 1.1rem;

    row-gap: .1rem;
  }

  .lp-hero {
    position: relative;
    flex-direction: column;
    height: 50vh;
  }

  .lp-hero-title {
    row-gap: 5rem;
    width: 100%;
  }

  .lp-hero-call {
    line-height: .1rem;
    display: flex;
    flex-direction: column;
    row-gap: .1rem;
  }

  .sku {
    font-size: 2rem;
    line-height: 2rem;
  }

  .lp-hero-image {
    position: absolute;
    width: auto;
    height: 60%;
    top: 80px;
    right: 0;
  }

  h1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
  }

  .product-data-lp {
    row-gap: .8rem;
  }

  .product-data-lp h2 {
    font-size: 1rem;
  }


  h3 {
    line-height: 1rem;
    width: 45%;
    font-size: .7rem;
  }

  .action {
    row-gap: 2rem;
  }

  .lp-content {
    flex-direction: column;
    row-gap: 1rem;
  }

  .product-specs {
    row-gap: .3rem;
    font-size: .7rem;
  }

  .product-module h2 {
    font-size: 1rem;
  }

  .lp-content-video {
    width: 100%;
  }

  .lp-content-video h4 {
    font-size: 1rem;
  }

  .lp-content-video video {
    height: auto;
    width: 100%;
  }

  .lp-buttons-row {
    flex-direction: column;
    row-gap: 1rem;
  }

  .lp-buttons-row button {
    width: 12rem;
    height: 2.8rem;
    font-size: 1rem;
  }

  .product-specs li span {
    font-size: 1rem;
  }

  .material-symbols-outlined {
    font-size: 2rem;
  }

  .overlay-video:hover {
    background-color: rgba(0, 0, 0, 0);
  }
}