.category-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2.25rem 1.5rem;

  background: var(--branco);
  border: 2px solid #ccc;
  border-radius: 2px;

  width: 15%;

  transition: 0.5s;

  cursor: pointer;
}

.category-card:hover {
  box-shadow: -3px 6px rgba(0, 0, 0);
  transition: 0.5s;
  transform: scale(1.05);
}

.category-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  margin-bottom: 2rem;
}

.card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
}

.card-text h3 {
  font-weight: 400;
  font-size: 1.5rem;
  min-height: 3rem;
}

.card-text p {
  font-weight: 400;
  font-size: 1rem;
  display: none;
}

.card-img {
  min-height: 5rem;
}

.card-img img {
  width: 100%;
  max-height: 4.5rem;
  max-width: 3.75rem;
}

.category-card-saiba-mais {
  font-weight: bold;
  font-size: 1rem;
}

@media only screen and (max-width: 1300px) {
  .category-card {
    width: 14%;
  }
}

@media only screen and (max-width: 1100px) {
  .category-card {
    width: 15.3%;
    padding: 1.75rem 1rem;
  }

  .category-card-content {
    row-gap: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 900px) {
  .category-card {
    width: 14.8%;
  }

  .card-text h3 {
    font-size: 1.2rem;
  }

  .card-text p {
    font-size: 0.7rem;
  }

  .card-img {
    min-height: 4rem;
  }

  .card-img img {
    width: 100%;
    max-height: 4rem;
    max-width: 3.25rem;
  }

  .category-card-saiba-mais {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 820px) {
  .category-card {
    padding: 1rem 0.8rem;
  }
}

@media only screen and (max-width: 750px) {
  .card-text h3 {
    font-size: 1rem;
  }

  .card-img {
    min-height: 3rem;
  }

  .card-img img {
    width: 100%;
    max-height: 3rem;
    max-width: 2.25rem;
  }

  .category-card-saiba-mais {
    font-size: 0.6rem;
  }

  .category-card-content {
    row-gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 670px) {
  .category-card {
    padding: 0.8rem 0.6rem;
  }

  .card-text h3 {
    font-size: 0.8rem;
    min-height: 2rem;
  }

  .card-text p {
    font-size: 0.5rem;
  }

  .card-img {
    min-height: 2.5rem;
  }

  .card-img img {
    width: 100%;
    max-height: 2.5rem;
    max-width: 1.75rem;
  }

  .category-card-saiba-mais {
    font-size: 0.6rem;
  }

  .category-card-content {
    row-gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 510px) {
  .category-card {
    padding: 0.7rem 0.5rem;
  }

  .card-text p {
    font-size: 0.45rem;
  }
}

@media only screen and (max-width: 450px) {
  .category-card {
    padding: 0.6rem 0.4rem;
  }

  .card-text h3 {
    font-size: 0.6rem;
    min-height: 1.8rem;
  }

  .card-img {
    min-height: 2rem;
  }

  .card-img img {
    width: 100%;
    max-height: 2rem;
    max-width: 1.25rem;
  }

  .category-card-saiba-mais {
    font-size: 0.5rem;
  }

  .category-card-content {
    row-gap: 0.3rem;
    margin-bottom: 0.3rem;
  }
}

@media only screen and (max-width: 400px) {
  .category-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;

    background: var(--branco);
    border: 2px solid #ccc;
    border-radius: 2px;

    width: 90%;

    transition: 0.5s;

    cursor: pointer;
  }

  .category-card:hover {
    box-shadow: -3px 6px rgba(0, 0, 0);
    transition: 0.5s;
    transform: scale(1.05);
  }

  .category-card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2rem;
    margin-bottom: 2rem;
  }

  .card-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.5rem;
  }

  .card-text h3 {
    font-weight: 400;
    font-size: 1.5rem;
    min-height: 3rem;
  }

  .card-text p {
    font-weight: 400;
    font-size: 1rem;
  }

  .card-img {
    min-height: 5rem;
  }

  .card-img img {
    width: 100%;
    max-height: 4.5rem;
    max-width: 3.75rem;
  }

  .category-card-saiba-mais {
    font-weight: bold;
    font-size: 1rem;
  }
}