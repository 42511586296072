.Point {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
  width: 27%;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
}

.icon img {
  width: 1.2rem;
  height: auto;
}

.icon p {
  font-weight: 400;
  font-size: 1rem;
  color: var(--preto);
}

.line {
  width: 1rem;
  border-bottom: 3px solid #000000;
}

@media only screen and (max-width: 360px) {
  .Point {
    width: 80%;
  }
}
