.product {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  column-gap: 2rem;
  padding: 2rem;
  border: 2px solid #000;
  border-radius: 2px;
}

.product-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 17rem;
  height: auto;
  row-gap: 1rem;
}

.product-img img {
  width: 90%;
  height: auto;
}

.product-data {
  width: 75%;
  height: 100%;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  font-size: 1.2rem;

}

.product-data h2 {
  font-family: 'Teko', sans-serif;
  font-weight: 700;
  font-size: 3rem;
}

.product-characteristics {
  display: flex;
  flex-direction: row;
  column-gap: 3rem;

  font-family: 'Teko', sans-serif;
  font-weight: 700;
  font-size: 2rem;
}

.product-models {
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  row-gap: 1rem;
}

.product-models h3 {
  font-family: 'Futura-PT', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
}

.models {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  row-gap: 2rem;
  column-gap: 4rem;
}

.vl {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  margin-top: 1rem;
  border-left: 2px solid #000;
}

@media only screen and (max-width: 1050px) {
  .navbar-buttons {
    position: relative;
    right: 2rem;
  }

  .product-data {
    width: 85%;
  }

  .product-data p {
    font-size: 1.05rem;
  }

  .product-models {
    padding-top: 0.8rem;
  }
}

@media only screen and (max-width: 850px) {
  .product {
    flex-direction: column;
  }

  .product-data h2 {
    margin-top: 1.1rem;
    margin-bottom: 0.4rem;
  }

  .product-data h3 {
    width: auto;
    margin-top: 0.6rem;
  }

  .product-data p {
    font-size: 1.1rem;
    width: 113%;
  }

  .product-models {
    width: 100%;
  }
}

@media only screen and (max-width: 665px) {
  .models {
    display: inline;
  }
}

@media only screen and (max-width: 540px) {
  .product-models h3 {
    font-size: 1.3rem;
  }

  .product-data h2 {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 440px) {
  .products {
    padding: 1rem 1rem;
  }

  .product-img {
    width: 90%;
  }

  .product-characteristics {
    font-size: 1.5rem;
  }

  .product-data h2 {
    font-size: 2rem;
  }

  .product-data p {
    font-size: 1.02rem;
  }
}