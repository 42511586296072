.hero {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 6rem;

  height: 100vh;
}

.hero-content {
  padding: 3rem;

  background-color: var(--preto);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-img {
  padding-top: 6rem;
  width: 30rem;
  height: 30rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.hero-img svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 1920px) {
  .hero {
    padding-top: 4rem;
  }

  .hero-img {
    width: 30rem;
    height: 30rem;
  }

  .hero-content {
    padding: 1rem;
  }
}

@media only screen and (max-width: 1050px) {
  .hero-img {
    display: none;
  }
}

@media only screen and (max-width: 610px) {
  .hero-content {
    padding: 2rem 1rem;
  }
}

@keyframes trapezio-animation1 {
  0% {
    transform: translateX(0)
  }

  30% {
    transform: translateX(0)
  }

  34% {
    transform: translateX(-50px)
  }

  39% {
    transform: translateX(-50px) translateY(-100px)
  }

  66% {
    transform: translateX(-50px) translateY(-100px)
  }

  70% {
    transform: translateX(0px) translateY(-100px)
  }

  74% {
    transform: translateY(0px)
  }

  100% {
    transform: translateX(0) translateY(0)
  }
}

#Trapezio1 {
  animation: trapezio-animation1 20s infinite;
}

@keyframes circle-animation1 {
  0% {
    transform: translateX(0)
  }

  32% {
    transform: translateX(0)
  }

  35% {
    transform: translateX(-49px)
  }

  40% {
    transform: translateX(-49px) translateY(-55px)
  }

  71% {
    transform: translateX(-49px) translateY(-55px)
  }

  75% {
    transform: translateX(0px) translateY(-55px)
  }

  79% {
    transform: translateY(0px)
  }

  100% {
    transform: translateX(0rem)
  }
}

#Circulo1 {
  animation: circle-animation1 20s infinite;
}

@keyframes circle-animation2 {
  0% {
    transform: translateX(0)
  }

  5% {
    transform: translateX(50px)
  }

  25% {
    transform: translateX(-20px)
  }

  29% {
    transform: translateX(205px)
  }

  36% {
    transform: translateX(205px) translateY(6px)
  }

  70% {
    transform: translateX(205px) translateY(6px)
  }

  75% {
    transform: translateX(0px) translateY(6px)
  }

  80% {
    transform: translateY(0px)
  }

  85% {
    transform: translateY(50px)
  }

  95% {
    transform: translateY(-10px)
  }

  100% {
    transform: translateX(0rem)
  }
}

#Circulo2 {
  animation: circle-animation2 20s infinite;
}

@keyframes triangulo-animation1 {
  0% {
    transform: translateX(0)
  }

  26% {
    transform: translateX(0)
  }

  30% {
    transform: translateX(-319px)
  }

  35% {
    transform: translateX(-319px) translateY(400px)
  }

  68% {
    transform: translateX(-319px) translateY(400px)
  }

  73% {
    transform: translateX(-319px) translateY(0px)
  }

  78% {
    transform: translateX(0px)
  }

  100% {
    transform: translateX(0) translateY(0)
  }
}

#Triangulo1 {
  animation: triangulo-animation1 20s infinite;
}

@keyframes triangulo-animation2 {
  0% {
    transform: translateX(0) rotateZ(0) translateX(0)
  }

  28% {
    transform: translateX(0)
  }

  30% {
    transform: rotateZ(-90deg)
  }

  34% {
    transform: rotateZ(-90deg)
  }

  38% {
    transform: rotateZ(-90deg) translateY(305px)
  }

  42% {
    transform: rotateZ(-90deg) translateY(305px) translateX(417px)
  }

  60% {
    transform: rotateZ(-90deg) translateY(305px) translateX(417px)
  }

  64% {
    transform: rotateZ(-90deg) translateY(0px) translateX(417px)
  }

  68% {
    transform: rotateZ(0deg) translateX(417px)
  }

  74% {
    transform: translateX(0px)
  }

  100% {
    transform: translateX(0) rotateZ(0) translateX(0)
  }
}

#Triangulo2 {
  animation: triangulo-animation2 20s infinite;
  transform-origin: center;
}

@keyframes triangulo-animation3 {
  0% {
    transform: translateX(0)
  }

  29% {
    transform: translateX(0)
  }

  33% {
    transform: rotateZ(90deg)
  }

  37% {
    transform: rotateZ(90deg)
  }

  40% {
    transform: rotateZ(90deg) translateY(60px)
  }

  44% {
    transform: rotateZ(90deg) translateY(60px) translateX(-250px)
  }

  60% {
    transform: rotateZ(90deg) translateY(60px) translateX(-250px)
  }

  64% {
    transform: rotateZ(90deg) translateY(60px) translateX(0px)
  }

  68% {
    transform: rotateZ(90deg) translateY(0px)
  }

  72% {
    transform: rotateZ(0deg)
  }

  100% {
    transform: translateX(0) rotateZ(0) translateX(0)
  }
}

#Triangulo3 {
  animation: triangulo-animation3 20s infinite;
  transform-origin: 10%;
}

@keyframes rectangle-animation1 {
  0% {
    transform: rotateZ(0) translateY(0) translateX(0)
  }

  33% {
    transform: translateX(0)
  }

  38% {
    transform: translateY(4.5px)
  }

  42% {
    transform: rotateZ(90deg) translateY(4.5px)
  }

  46% {
    transform: rotateZ(90deg) translateY(4.5px) translateX(130px)
  }

  60% {
    transform: rotateZ(90deg) translateY(4.5px) translateX(130px)
  }

  64% {
    transform: rotateZ(0deg) translateY(4.5px) translateX(130px)
  }

  69% {
    transform: translateY(4.5px) translateX(0px)
  }

  73% {
    transform: translateY(0px)
  }

  100% {
    transform: rotateZ(0) translateY(0) translateX(0)
  }
}

#Retangulo1 {
  animation: rectangle-animation1 20s infinite;
  transform-origin: center;
}

@keyframes rectangle-animation2 {
  0% {
    transform: translateX(0) rotateZ(0) translateX(0)
  }

  35% {
    transform: translateX(0)
  }

  39% {
    transform: rotateZ(90deg)
  }

  45% {
    transform: rotateZ(90deg) translateY(450px)
  }

  49% {
    transform: rotateZ(90deg) translateY(450px) translateX(180px)
  }

  67% {
    transform: rotateZ(90deg) translateY(450px) translateX(180px)
  }

  72% {
    transform: rotateZ(90deg) translateY(0px) translateX(180px)
  }

  76% {
    transform: rotateZ(90deg) translateX(0px)
  }

  80% {
    transform: rotateZ(0deg)
  }

  100% {
    transform: translateX(0) rotateZ(0) translateX(0)
  }
}

#Retangulo2 {
  animation: rectangle-animation2 20s infinite;
  transform-origin: center;
}

@keyframes rectangle-animation3 {
  0% {
    transform: translateX(0px) translateY(0px)
  }

  32% {
    transform: translateX(0)
  }

  37% {
    transform: translateX(-40px)
  }

  40% {
    transform: translateX(-40px) translateY(-60px)
  }

  68% {
    transform: translateX(-40px) translateY(-60px)
  }

  71% {
    transform: translateX(0px) translateY(-60px)
  }

  75% {
    transform: translateY(0px)
  }

  100% {
    transform: translateX(0px) translateY(0px)
  }
}

#Retangulo3 {
  animation: rectangle-animation3 20s infinite;
  transform-origin: center;
}

@keyframes rectangle-animation4 {
  0% {
    transform: translateX(0) rotateZ(0) translateX(0)
  }

  26% {
    transform: translateX(0)
  }

  30% {
    transform: rotateZ(90deg)
  }

  43% {
    transform: rotateZ(90deg) translateY(513px)
  }

  48% {
    transform: rotateZ(90deg) translateY(513px) translateX(226px)
  }

  65% {
    transform: rotateZ(90deg) translateY(513px) translateX(226px)
  }

  70% {
    transform: rotateZ(90deg) translateY(0px) translateX(226px)
  }

  74% {
    transform: rotateZ(0deg) translateX(226px)
  }

  77% {
    transform: translateX(0px)
  }

  100% {
    transform: translateX(0) rotateZ(0) translateX(0)
  }
}

#Retangulo4 {
  animation: rectangle-animation4 20s infinite;
  transform-origin: center;
}