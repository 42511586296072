.product-model {
  display: flex;
  flex-direction: column;
}

.product-model h4 {
  font-family: 'Teko', sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
}

.product-model h5 {
  font-weight: 500;
}

.files {
  display: flex;
  flex-direction: row;
  height: 4rem;
  width: auto;
  column-gap: 1rem;
  margin-top: 1rem;
}

.file {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.file img {
  width: 3rem;
  height: 100%;
}

.file span {
  font-weight: 600;
  font-size: .8rem;
}