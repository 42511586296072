.sidebar {
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 30%;
  background: #171717;
  transition: width 0.35s;
  box-sizing: border-box;
}

.colapse .container {
  display: none;
}

.colapse {
  width: 0%;
}

.container {
  margin: 1rem 1rem;
}

.sidebar-tittle {
  display: flex;
  align-items: center;
  height: 2rem;
  color: #fafafa;
  font-size: 1.5rem;
  font-weight: bold;
}

.sidebar-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: -6px;
  margin-bottom: -2px;
}

.sidebar-dropdown .sidebar-dropdown-title {
  display: flex;
  align-items: center;
  background-color: inherit;
  font-family: inherit;

  display: flex;
  column-gap: 2px;

  color: #fafafa;
  font-size: 18px;
  font-weight: bold;

  border: none;
  outline: none;
}

.sidebar-dropdown-content {
  display: none;
  background-color: #171717;
  min-width: 110px;
  border-radius: 2px;
  margin-left: 1rem;
}

.sidebar-dropdown-content a {
  color: var(--branco);
  padding: 0.5rem;
  text-decoration: none;
  display: block;
  text-align: left;
}

.sidebar-dropdown-content a:hover {
  color: #949494;
}

.sidebar-dropdown:hover .sidebar-dropdown-content {
  display: block;
}

.sidebar-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  color: #fafafa;
  font-size: 18px;
  font-weight: bold;
  margin-top: 1rem;
}

.sidebar-links a {
  text-decoration: none;
  color: var(--branco);
}

@media only screen and (min-width: 950px) {
  .sidebar {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .sidebar {
    width: 100%;
  }

  .colapse {
    width: 0%;
  }

  .container {
    margin-left: 4rem;
  }

  .sidebar-tittle {
    font-size: 2rem;
  }

  .sidebar-dropdown .sidebar-dropdown-title {
    font-size: 1.5rem;
  }
  .sidebar-links {
    font-size: 1.5rem;
  }
}
